import React from 'react';
import Container from '../components/Container';
import twStyled from '../twStyled';

export default function Footer() {
  return (
    <footer className='bg-slate-100/75'>
      <Content>footer</Content>
    </footer>
  );
};

const Content = twStyled(Container, ['py-4']);
