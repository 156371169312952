import React from 'react';
import Container from '../components/Container';
import Link from '../components/Link';
import Text from '../components/Text';
import twStyled from '../twStyled';

export default function Header() {
  return (
    <header className='bg-slate-200/75'>
      <Nav>
        <div className='flex justify-end gap-10'>
          <Link to='/'>Home</Link>
          <Link to='/about'>About</Link>
          <Link to='/projects'>Projects</Link>
        </div>
      </Nav>
    </header>
  );
};

const Nav = twStyled(Container, ['py-4']).withComponent('nav')
